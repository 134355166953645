body.page-template-event {

	section#main {
		.wrapper {
			max-width: 960px;
		}
	
		section#overview {
			.details {
				display: flex;
				flex-wrap: wrap;
				padding: 0 0 25px 0;

				@include tablet-portrait {
					padding: 0 0 50px 0;

				}

				.detail {
					width: 100%;

					@include tablet-portrait {
						width: 33.3333%;
						text-align: center;
					}

					& + .detail {
						padding: 20px 0 0 0;

						@include tablet-portrait {
							padding: 0;
						}
					}

				}

				h5 {
					font-family: $brothers;
					color: $gold;
					text-transform: uppercase;
					letter-spacing: 0.05em;
					font-size: 1.5em;
					padding: 0 0 10px 0;
				}

				p {
					font-size: 1.25em;
					font-family: $source-sans-pro;
					font-weight: bold;
				}
			}

			.description {

				p {
					font-size: 1em;
					line-height: 1.5em;
					font-family: $merriweather;

					@include tablet-portrait {
						font-size: 1.125em;
					}

					& + p {
						padding: 20px 0 0 0;
					}
				}
			}
		}

		section#results {
			padding: 50px 0 0 0;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;

			& > div {
				width: 100%;

				@include tablet-portrait {
					width: 47.5%;

					&.results-link {
						width: 100%;
					}
				}
			}

			h3 {
				color: $gold;
				padding: 0 0 20px 0;
			}

			ul, ol {
				border: 2px solid #f5f5f5;

				li {
					display: block;
					padding: 15px 20px;
					font-size: 0.8125em;
				}
			}

			.standings {
				li {
					&:nth-child(odd) {
						background: #f5f5f5;
					}
				}
			}

			.spirit {
				padding: 50px 0 0 0;

				@include tablet-portrait {
					padding: 0;
				}

				li {
					&:nth-child(even) {
						background: #f5f5f5;
					}
				}
			}

			.results-link {
				text-align: center;
				padding: 25px 0 0 0;

				a {
					display: inline-block;
					font-size: 1.25em;
					letter-spacing: 0.05em;
					background: $slate;
					transition: background 0.3s ease-in-out;

					&:hover {
						background: $gold;
					}
				}
			}
		}

		section#videos {
			padding: 50px 0 0 0;

			@include tablet-portrait {
				padding: 100px 0 0 0;
			}

			h3 {
				text-transform: uppercase;
				letter-spacing: 0.05em;
				font-size: 1.25em;
				padding: 0 0 20px 0;
				color: $gold;
			}

			.video-wrapper {
				display: grid;
				grid-gap: 20px 0;
				grid-template-columns: 1fr;

				@include mobile-landscape {
					grid-template-columns: 1fr 1fr;
					grid-gap: 50px 20px;
				}

				@include tablet-portrait {
					grid-template-columns: 1fr 1fr 1fr;
				}
			}

			.video {
				padding: 0 0 50px 0;

				.info {
					padding: 10px 0 0 0;
					text-align: center;

					h4 {
						font-family: $source-sans-pro;
						font-size: 1em;
						line-height: 1.25em;

						a {
							color: $slate;

							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}

		}
	}
}