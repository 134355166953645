body.page-template-teams {

	#teams-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		.team {
			width: 48%;
			margin: 0 0 50px 0;

			@include mobile-landscape {
				width: 31.3333%;
			}

			@include tablet-landscape {
				width: 23%;
				margin: 0 0 100px 0;
			}

			.logo {
				

				img {
					margin: 0 auto;
					max-width: 96px;
				}
			}

			.info {
				text-align: center;
				padding: 10px 0 0 0;

				h3 {
					font-family: $source-sans-pro;
					font-size: 0.875em;
					letter-spacing: 0.05em;
					text-transform: uppercase;

					a {
						color: $slate;
					}

				}
			}

		}

	}


}