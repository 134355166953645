body.template-schedule-results {

	#main {

		.wrapper {
			display: block;
		}

		#tab-links {
			border-bottom: 1px solid #eee;
			display: flex;
			justify-content: center;
			margin: 0 0 40px 0;

			a {
				display: inline-block;
				padding: 12px 24px;
				background: $white;
				border: 1px solid #eee;
				color: $slate;
				text-transform: uppercase;
				letter-spacing: 0.02em;
				font-size: 1.125em;
				font-weight: bold;

				&.active {
					background: $slate;
					color: $white;
				}
			}
		}

		#tabs {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		
		article {
			width: 100%;
			display: none;

			h4 {
				padding: 0 0 20px 0;
				color: $gold;
				font-size: 1.5em;
				text-transform: uppercase;
				letter-spacing: 0.03em;
				width: 100%;

			}

			.score-reporter {
				display: block;
				text-align: center;
				margin: -20px 0 40px 0;
				font-weight: bold;
				font-size: 0.875em;
				text-decoration: underline;
			}


			.pool {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				margin: 0 0 100px 0;



				h5 {
					text-transform: uppercase;
					letter-spacing: 0.03em;
					font-family: $source-sans-pro;
					padding: 0 0 10px 0;
				}

				.standings {
					width: 100%;

					@include tablet-portrait {
						width: 48.5%;
					}
				
					.header,
					.row {
						display: flex;
						align-items: center;

						.team {
							width: 60%;
						}
						.win-loss,
						.tie {
							width: 20%;
						}
					}

					.header {
						background: $slate;
						color: $white;
						padding: 10px;

						span {
							font-weight: bold;
						}
					}

					.row {
						padding: 20px 10px;
						border-bottom: 1px solid $light-gray;
						&:nth-child(odd) {
							background: #fafafa;
						}
						
						.team {
							font-weight: bold;
							font-size: 1em;

							a {
								color: $slate;
							}
						}

						.win-loss {
							font-size: 1em;
						}

						.tie {
							font-size: 0.875em;
							font-style: italic;
						}
					}	
				}

				.games {
					width: 100%;
					padding: 50px 0 0 0;

					@include tablet-portrait {
						width: 48.5%;
						padding: 0;
					}
				}

			}

			&#bracket {
				h5 {
					text-align: center;
					text-transform: uppercase;
					font-family: $source-sans-pro;
				}
				section#tournament {
				  display:flex;
				  flex-direction:row;
				  justify-content: center;
				}
				.round{
				  display:flex;
				  flex-direction:column;
				  justify-content:center;
				  width: 200px;
				  list-style:none;
				  padding:0;
				}
				.round .spacer{ flex-grow: 1; min-height: 40px; }
				.round .spacer.first,
				.round .spacer:last-of-type { flex-grow: 0.5; }

				.round .game-spacer{
					flex-grow:1;
				}



				li.game {
					font-size: 0.8125em;

					a {
						color: $slate;

						&:hover {
							color: $gold;
						}
					}

					span {
				    	float: right;
				    	margin-right: 10px;
				  	}

				  	&-top {
					  	border-bottom:1px solid #aaa;
					  	padding: 0 0 5px 10px;
					  	font-weight:bold;
					  	min-height: 18px;
				  	}

				  	&-spacer { 
					    border-right: 1px solid #aaa;
					    min-height: 80px;
					    display: flex;
					    align-items: center;
					    font-size: 0.75em;
					    padding: 10px;
				 	}
					
					&-bottom{ 
						border-top:1px solid #aaa;
					    padding: 5px 0 0 10px;
					    font-weight:bold;
					}

					&-top.empty,
					&-spacer.empty,
					&-bottom.empty {
						border-color: transparent;
						opacity: 0;
					}

					&-top.empty,
					&-bottom.empty {
						min-height: 13px;
					}

				}











			}



		}





	}





}