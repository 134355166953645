@font-face{
	font-family: 'Brothers';
	src:url("fonts/brothers-reg.eot");
	src:url("fonts/brothers-reg.eot?#iefix") format("embedded-opentype"),
		url("fonts/brothers-reg.woff") format("woff"),
		url('fonts/brothers-reg.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


/* Colors
----------------------------------*/
$white: #fff;
$black: #000;
$slate: #2a2a2a;
$light-gray: #eee;
$gold: #BCA95B;
$green: #5F634F;
$red: #b20838;
$blue: #002a5c;


/* Typography
----------------------------------*/
$source-sans-pro: 'Source Sans Pro', sans-serif;
$merriweather: 'Merriweather', serif;
$brothers: 'Brothers', sans-serif;