body.page-template-road-to-nationals {


	section#main {
		padding: 0;
	}


	section.round {
		width: 100%;
		padding: 100px 0;

		.wrapper {
			display: block !important;
		}

		h3, h4, h5 {
			font-family: $source-sans-pro;
		}

		h2 {
			color: $slate;
			font-size: 2em;
			text-align: center;
			padding: 0 0 50px 0;

			@include mobile-landscape {
				font-size: 3em;
			}

			@include tablet-portrait {
				font-size: 4em;
			}

			span {
				color: $gold;
			}

		}

		div.region {
			display: none;
		}


		div.tab-links {
			display: flex;
			width: 100%;
			justify-content: center;
			flex-wrap: wrap;
			border-bottom: 1px solid #d5d5d5;
			margin: 0 auto 50px auto;
			max-width: 600px;



			a {
				display: inline-block;
				background: $slate;
				color: $white;
				padding: 5px 8px;
				font-size: 0.75em;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 0.02em;
				margin: 0 5px 10px 5px;



				&.active,
				&:hover {
					background: $gold;
				}
			}
		}

		div.tabs {

			.region {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				h3 {
					width: 100%;
					padding: 0 0 40px 0;
					text-align: center;
					font-size: 2em;
					font-weight: bold;
				}

				.tournament {
					width: 100%;
					text-align: center;
					margin: 0 0 50px 0;


					@include mobile-portrait {
						width: 50%;
					}

					@include tablet-landscape {
						width: 33%;
						margin: 0;
					}



					h4 {
						font-size: 1.25em;
						padding: 0 0 5px 0;

					}

					h5 {
						font-size: 0.875em;
						padding: 0 0 10px 0;
						font-weight: normal;
						font-style: italic;
					}

					p {
						font-size: 0.875em;
						line-height: 1.25em;
						padding: 0 0 10px 0;
					}

					a {
						font-size: 0.875em;
						text-decoration: underline;
					}
				}

				&#conf-south-central {
					.tournament {
						@include tablet-landscape {
							width: 25%;

						}
					}
				}

			}

		}


		&#regionals {
			background: #fafafa;
	
			div.tabs {

				.region {

					h3 {
						padding: 0 0 10px 0;
					}

					h5 {
						font-size: 1em;
						padding: 0 0 20px 0;
					}

					p {
						font-size: 1em;
						padding: 0 0 20px 0;
					}

					a {
						font-size: 1em;
					}
				}
			}

		}

		&#nationals {
			.tournament {
				text-align: center;


				h3 {
					font-size: 1.5em;
					padding: 0 0 5px 0;

				}

				h5 {
					font-size: 0.875em;
					padding: 0 0 20px 0;
					font-weight: normal;
					font-style: italic;
				}

				a {
					display: block;
					font-size: 1em;
					line-height: 1.25em;
					margin: 0 0 10px 0;
					font-weight: bold;
					text-decoration: underline;
				}


			}
		}

	}


	section.down-arrows {
		padding: 50px 0;

		@include tablet-landscape {
			padding: 100px 0;
		}
	}



}