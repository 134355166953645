body.page-template-overview {


	#main {
		.wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
		}

		article {
			width: 100%;
			padding: 0 0 50px 0;

			@include tablet-landscape {
				width: 55%;
				padding: 0;
			}

			.about {
				padding: 0 0 50px 0;

				p {
					font-family: $merriweather;
					font-size: 0.9375em;
					line-height: 1.75em;
				}
			}

			.faqs {

				.faq {
					margin: 0 0 20px 0;

					.question {
						color: $slate;
						font-weight: bold;
						font-family: $brothers;
						font-size: 1.125em;
						display: block;
						padding: 20px;
						background: $light-gray;
						transition: none;

						&:hover,
						&.active {
							color: $white;
							background: $gold;
						}

					}

					.answer {
						display: none;
						padding: 20px 20px 0 20px;

						p {
							font-size: 0.9375em;
							line-height: 1.75em;
							font-family: $merriweather;
						}

						p:last-of-type {
							margin: 0;
						}
					}
				}
			}
		}

		.aside-wrapper {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			@include tablet-landscape {
				width: 42.5%;
			}

			aside#latest-news {
				width: 100%;
			}

		}

	}

}