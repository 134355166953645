body {
	background: $white;
}

.wrapper {
	margin: 0 auto;
	max-width: 1200px; 
	padding: 0 25px;
    transition: padding 0.2s ease-in;

    @include tablet-portrait {
		padding: 0 50px;
    }

	@media screen and (min-width: 1260px) {
		padding: 0;
	}
}

body.error404 {
	#main {
		article {
			min-height: 600px;
		}
	}
}

.cover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.hero-link {
	display: block;
}

.hero-image {
	height: 240px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;


    @include mobile-landscape {
		height: 360px;
    }

	@include tablet-landscape {
		height: 480px;
	}

	@include desktop {
		height: 600px;
	}

	.wrapper {
		display: flex;
		align-items: flex-end;
		height: 100%;
	}

	.info {
		padding: 0 0 25px 0;

	    @include mobile-landscape {
			padding: 0 0 50px 0;
	    }
	}

	h1 {
		color: $white;
		text-transform: uppercase;
		font-size: 1.25em;
		max-width: 900px;

	    @include mobile-landscape {
			font-size: 2.25em;
	    }

		@include tablet-landscape {
			font-size: 3em;
		}

		@include desktop {
			font-size: 3.5em;
		}

		span {
			background: rgba(188, 169, 91, 0.8);
			padding: 10px;
			display: inline-block;

			@include mobile-landscape {
				padding: 15px 20px;
			}
		}
	}

	h2 {
		color: $white;
		text-transform: uppercase;
		font-size: 0.875em;

	    @include tablet-portrait {
			font-size: 1.25em;
	    }

		@include desktop {
			font-size: 1.5em;
		}

		span {
			background: rgba(42, 42, 42, 0.8);
			padding: 10px;
			display: inline-block;

			@include mobile-landscape {
				padding: 15px 20px;
			}
		}
	}




}


section#main  {
	padding: 30px 0;

	@include tablet-portrait {
		padding: 50px 0;
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}

	article {
		width: 100%;
		padding: 0 0 50px 0;

		@include tablet-landscape {
			width: 55%;
			padding: 0;
		}
	}

	.aside-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include tablet-landscape {
			width: 42.5%;
		}
	}

}

aside#latest-news {

	article {
		border: 1px solid rgba(0, 42, 92, 0.2);
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
		padding: 20px !important;
		width: 100% !important;
		margin: 0 0 20px 0;


		h5 {
			color: $gold;
			font-size: 0.875em;
			padding: 0 0 5px 0;
		}

		h4 {
			a {
				color: $green;
				font-size: 1.125em;

				&:hover {
					text-decoration: underline;
				}
			}
		}

	}

	.btn {
		margin: 10px auto 0 auto;
		max-width: 200px;
	}


}



	.game-block {
		padding: 0 0 50px 0;

		h4 {
			text-transform: uppercase;
			font-family: $source-sans-pro;
			font-size: 0.875em;
			padding: 0 0 10px 0;
		}

		.fixture:last-of-type {
			border-bottom: 1px solid #d5d5d5;
		}
	}


	.fixture, .result {
		background: #fafafa;
		border-top: 1px solid #d5d5d5;
		padding: 15px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		&:nth-child(odd) {
			background: $white;
		}


		.time {
			font-size: 0.75em;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 0.02em;
			width: 100%;
			padding: 0 0 15px 0;
			font-weight: bold;
			color: #8a8a8a;
		}

		.match-up {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: center;


			.versus {
				text-align: center;
				width: 30%;
				font-weight: bold;
				color: $slate;
			}

			.team-a,
			.team-b {
				font-size: 1em;
				font-weight: bold;
				width: 30%;
				max-width: 200px;

				a {
					color: $slate;
				}
			}

			.team-a {
				text-align: right;
			}
			.team-b {
				text-align: left;
			}

		}

		.link {
			font-size: 0.75em;
			text-align: center;
			width: 100%;
			padding: 15px 0 0 0;
			text-transform: uppercase;
			letter-spacing: 0.02em;
			font-weight: bold;
			color: #8a8a8a;
		}

	}

