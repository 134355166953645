body.template-password-protected-photos {

	#main {
		min-height: 600px;

		article {

			.password-form {
				p {
					margin: 0 0 20px 0;
					font-size: 1.25em;
					line-height: 1.125em;

					label, input {
						
					}

					label {
						font-size: 0.875em;
						input[type="password"] {
							padding: 5px 5px 4px 5px;
							margin: 0 0 0 10px;

							&:active, &:focus {
								outline: none;
							}
						}
					}


					
					input[type="submit"] {
						background: $gold;
						color: $white;
						font-family: $brothers;
						font-weight: bold;
						text-transform: uppercase;
						font-size: 0.875em;
						line-height: 1em;
						display: inline-block;
						padding: 10px 24px 8px;
						text-align: center;
						border: none;
						margin: 0 0 0 10px;

						&:active, &:focus {
							outline: none;
						}
					}
				}
			}


			.photos {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 50px 0 0 0;


				.photo {
					width: 100%;
					margin: 0 0 30px 0;
	
					@include mobile-landscape {
						width: 48%;
					}

					@include tablet-portrait {
						width: 31.3333%;
					}

					span {
						padding: 10px 0 0 0;
						display: block;
						text-align: center;
						font-size: 0.875em;
					}

				}
			}



		}
	}

}