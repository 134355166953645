body.single-team {

	section#main {

		article {

			#bio {

				img {
					float: left;
					max-width: 96px;
					margin: 10px 20px 20px 0;
				}
				p {
					font-family: $merriweather;
					font-size: 0.9375em;
					line-height: 1.75em;
				}

				ul, ol {
					margin: 0 0 30px 30px;

					li {
						margin: 0 0 10px 0;
						font-family: $merriweather;
						font-size: 0.875em;
						line-height: 1.5em;
					}
				}

				ul {
					list-style-type: disc;
				}

				ol{
					list-style-type: decimal;
				}

				h4 {
					padding: 0 0 10px 0;
					font-family: $source-sans-pro;
					font-size: 1.25em;
				}
			}

			#team-history {
				clear: both;

				h5 {
					font-family: $source-sans-pro;
					font-size: 1em;
				}
			}

		}


		.aside-wrapper {
			justify-content: flex-start;

			@include tablet-landscape {
				padding: 0 0 0 40px;
			}
		}

		aside#roster {
			width: 100%;

			h3 {
				padding: 0 0 10px 0;
				font-size: 1.25em;
				border-bottom: 2px solid;
				font-family: $source-sans-pro;
			}

			div.player {
				display: flex;
				width: 100%;
				flex-wrap: nowrap;
				padding: 15px 5px;
				font-size: 0.875em;

				& + div.player {
					border-top: 1px solid #eee;
				}


				span.number {
					width: 10%;
					font-weight: bold;
				}

				span.name {
					width: 70%;
				}

				span.year {
					width: 25%;
				}
			}

			p {
				padding: 0 0 40px 0;
				font-size: 0.9375em;
			}
		}

	}
}