body.page-template-callahan {

	section#main {

		section#overview {
			width: 100%;
			padding: 0;

			.overview-wrapper {
				max-width: 960px;
				margin: 0 auto;
			}

			p {
				font-size: 1.125em;
				line-height: 1.5em;
				font-family: $merriweather;

				& + p {
					padding: 20px 0 0 0;
				}
			}

			.vote {
				padding: 50px 0 75px 0;
				text-align: center;

				.button {
					.btn {
						display: inline-block;
						font-size: 1.125em;
					}

					& + .button {
						padding: 25px 0 0 0;
					}
				}
			}

			.tabs {
				max-width: 960px;
				margin: 0 auto 40px auto;
				border-bottom: 1px solid #eee;
				display: flex;
				justify-content: center;

				a {
					display: inline-block;
					padding: 12px 24px;
					background: $white;
					border: 1px solid #eee;
					color: $slate;
					text-transform: uppercase;
					letter-spacing: 0.02em;
					font-size: 1.125em;
					font-weight: bold;

					&.active {
						background: $slate;
						color: $white;
					}
				}
			}

		}

		article {
			width: 100%;

			h2 {
				padding: 0 0 40px 0;
			}
		}

		#rules {
			max-width: 960px;
			margin: 0 auto;

			h4 {
				font-family: $source-sans-pro;
				padding-bottom: 5px;
			}

			p {
				font-size: 0.875em;
				line-height: 1.375em;
				margin: 0;

				& + p {
					padding: 20px 0 0 0;
				}

				& + h4 {
					padding-top: 20px;
				}
			}
		}


		#nominees {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			max-width: 960px;
			margin: 0 auto;

			.header {
				width: 100%;

				h2 {
					color: $gold;
				}
			}

			.nominee {
				width: 48%;
				margin: 0 0 50px 0;

				@include mobile-landscape {
					width: 31.3333%;
				}

				@include tablet-landscape {
					margin: 0 0 100px 0;
				}

				.headshot {
					

					img {
						margin: 0 auto;
					}
				}

				.info {
					text-align: center;
					padding: 10px 0 0 0;

					h3 {
						font-family: $source-sans-pro;
						font-size: 1.25em;
						width: 100%;
						text-align: center;
						
						em {
							display: block;
							text-transform: uppercase;
							letter-spacing: 0.05em;
							font-style: normal;
							font-size: 0.7em;
							padding: 3px 0 0 0;
						}

						a {
							color: $slate;
						}

					}
				}

			}

		}

	}

	section#sponsors,
	section#media-partners {
		display: none;
	}

}