header {
	padding: 30px 0;

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.logo {
		display: flex;
		align-items: center;

		img {
			margin-right: 20px;

		}
		h1 {
			display: none;
			text-transform: uppercase;
			font-size: 1.5em;
			letter-spacing: 0.025em;

			@include mobile-landscape {
				display: block;
			}

			@include tablet-landscape {
				font-size: 2.25em;
			}


			a {
				color: $slate;
			}
		}	
	}

	#toggle {
		width: 34px;
	    cursor: pointer;
	    padding: 8px 5px 8px 5px;
	    transition: all 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
	    margin-right: 10px;

		.patty {
		    width: 40px;
		    height: 5px;
		    background: $slate;
		    transition: all 0.3s;

			&:after, &:before {
			    content: '';
			    display: block;
			    width: 40px;
			    height: 5px;
			    background: $slate;
			    position: absolute;
			    z-index: -1;
			    transition: all 0.3s;
			}
			&:before {
			    top: -10px;
			}
			&:after {
			    top: 10px;
			}
		}
	}

	&.open {

		#toggle .patty {
			background: none;

			&:after {
			    top: 0;
			    transform: rotate(45deg);
			}
			&:before {
			    top: 0;
			    transform: rotate(-45deg);
			}
		}

	}


	a.watch-live {
		display: none;
		margin: 0 40px 0 auto;
		padding: 8px 12px 6px 12px;
		color: $gold;
		border: 1px solid $gold;
		font-weight: 700;
		font-size: 0.8125em;
		text-transform: uppercase;
		letter-spacing: 0.05em;

		&:hover {
			background: $gold;
			color: $white;
		}

		@include tablet-landscape {
			display: inline-block;
		}
	}



}

nav#dropdown {
	display: none;
	background: $light-gray;
	padding: 25px 0 10px 0;

	@include tablet-landscape {
		padding: 50px 0;
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.col {
			flex-basis: 100%;
			padding: 0 0 15px 0;



			@include tablet-landscape {
				flex-basis: 25%;
				text-align: left;
				padding: 0;
			}
			
		}

	}

	@include tablet-landscape {
		.col.news {
			display: none;
		}
	}

	h4 {
		text-transform: uppercase;
		font-size: 1.5em;
		line-height: 1em;
		padding: 0 0 20px 0;

		a {
			color: $slate;
		}
	}

	p {
		display: none;

		@include tablet-landscape {
			display: block;
		}

		font-size: 0.875em;
		line-height: 1.375em;
		padding: 0 0 20px 0;
	}

	ul {
		display: none;

		@include tablet-landscape {
			display: block;
		}

		li {
			padding: 0 0 5px 0;

			&.divider {
				text-indent: -9999px;
			}

			a {
				font-size: 0.9375em;
				font-weight: bold;
				color: $slate;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}


nav#bar {
	background: $gold;
	color: $white;
	padding: 20px 0;

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		@include tablet-landscape {
			justify-content: flex-start;
		}
	}

	h1 {
		text-transform: uppercase;
		font-size: 1.5em;

		a {
			color: $white;
		}
	}

	#menu-toggle {
		width: 32px;
		height: 18px;
		background: url(images/menu-toggle.png) no-repeat 0 0;
		background-size: cover;
		display: block;
		text-indent: -99999px;		
		margin: 0 3px 0 0;

		@include tablet-landscape {
			display: none;
		}
	}

	.menu {
		display: none;
		width: 100%;
		padding: 10px 0 0 0;

		@include tablet-landscape {
			display: flex !important;
			width: auto;
			padding: 0;
		}


		a {
			color: $white;
			display: block;
			font-size: 0.9375em;
			font-weight: bold;
			margin: 10px 0;

			@include tablet-landscape {
				margin: 0 0 0 20px;
			}

			@include desktop {
				margin: 0 0 0 40px;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}

}


#sub-bar {
	background: $slate;
	padding: 15px 0;
	display: none;

	@include tablet-landscape {
		display: block !important;
	}
	
	.wrapper {
		@include tablet-portrait {
			display: flex !important;
		}
	}

	a {
		color: $white;
		display: block;
		font-size: 0.875em;
		margin: 10px 0;

		@include tablet-portrait {
			font-size: 0.875em;
			margin: 0 40px 0 0;
		}

		&:hover {
			text-decoration: underline;
		}
	}


}





#utility-nav {
	background: $light-gray;
	padding: 12px 0 10px 0;
	border-bottom: 1px solid rgba(42, 42, 42, 0.2);
	display: none;

	@include tablet-portrait {
		display: block;
	}

	.wrapper {
		display: flex;
		justify-content: flex-end;
	}

	div.division {
		display: flex;
		margin: 0 0 0 30px;

		a, span, strong {
			font-size: 0.8215em;
		}

		span.dot {
			display: inline-block;
			padding: 0 5px;
		}
		strong {
			padding: 0 7px 0 0;
		}
	}

	a {
		font-size: 0.8125em;
		font-weight: bold;
		display: inline;
		color: $slate;

		&:hover {
			text-decoration: underline;
		}

	}

}