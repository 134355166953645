body.template-videos,
body.single-video {

	#video-player {
		width: 100%;
		background: $slate;
		padding: 50px 0;
		min-height: 380px;

		@include tablet-landscape {
			min-height: 700px;
		}

		.info {
			color: $white;
			padding: 25px 0 0 0;

			h3 {
				font-size: 1.25em;
				padding: 0 0 10px 0;

				@include tablet-portrait {
					font-size: 2em;
				}
			}

			p {
				font-size: 0.875em;
				line-height: 1.25em;

				@include tablet-portrait {
					font-size: 1.25em;
				}
			}
		}
	}


	#video-gallery {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.thumbnail {
			width: 48%;
			margin: 0 1% 30px 1%;


			@include mobile-landscape {
				width: 31.3333%;
			}

			@include tablet-portrait {
				width: 23%;
			}

			span {
				padding: 10px 0 0 0;
				display: block;
				font-size: 0.875em;
				font-weight: bold;
				line-height: 1.25em;
			}
		}
	}


}