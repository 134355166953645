body.page-template-history {

	section#main {

		article {
			@include tablet-landscape {
				width: 75%;
			}

			h3 {
				color: $gold;
				padding: 20px 0 10px 0;
				font-size: 1.5em;
			}
		}
		table {
			width: 100%;

			thead {
				font-weight: bold;

				td {
					padding: 10px;
					border-bottom: 2px solid $slate;
					font-family: $source-sans-pro;
				}
			}

			.year {
				width: 10%;
			}

			.champion,
			.runner-up {
				width: 27.5%;
			}

			.location {
				width: 20%;
			}

			.results {
				width: 15%;

				a {
					text-decoration: none;
					font-size: 0.875em;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			tbody {
				tr:nth-child(even) {
					background: #fafafa;
				}
				td {
					border-top: 1px solid #eee;
					padding: 12px 10px;
					font-size: 0.875em;
					font-family: $source-sans-pro;

					&.champion {
						font-weight: bold;
					}
				}
			}

			@media screen and (max-width: 639px) {
				display: block;

				tbody, tr, td {
					display: block;
				}

				thead {
					display: none;
				}

				tbody {

					tr {
						padding: 20px 20px;
					}

					td {
						border: none;
						padding: 0;
						font-size: 1em;
					}

					.year,
					.champion,
					.runner-up,
					.location,
					.results {
						width: 100%;
					}

					.year {
						font-size: 1.75em;
						font-weight: bold;
						padding: 0 0 5px 0;
					}

					.champion {
						padding: 0 0 5px 0;
						&:before {
							content: "Champion: ";
						}
					}

					.runner-up {
						padding: 0 0 5px 0;

						&:before {
							content: "Runner-up: ";
						}
					}

					.location {

						&:before {
							content: "Location: ";
						}
					}

					.results {
						padding: 20px 0 0 0;
						a {
							font-size: 1em;
						}
					}

				}
			}
		}
	}



}