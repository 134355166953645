body.page-template-default.page {

	section#generic-page {
		padding: 50px 0;

		@include tablet-portrait {
			padding: 50px 0 100px 0;
		}

		article {

			.article-header {
				padding: 0 0 20px 0;

				h1 {
					font-size: 3em;
					color: $slate;
					text-transform: uppercase;
				}
			}

			.article-body {

				& > *:not(img) {
					max-width: 800px;		    
				}

			}


		}


	}


}